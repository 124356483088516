import React, { Component } from "react";
import "./Feedback.css";
import CustomBtn from "../../components/CustomBtn";
import commas from "../../assets/logo/itribe-logo2.svg";
import MuiAlert from "@material-ui/lab/Alert";

import { database } from "../../firebaseConfig";

import { ref, set, push } from "firebase/database";
import { Snackbar } from "@material-ui/core";
import Footer from "../footer/Footer";
import { primaryColor, secondaryColor } from "../../utils/constants";
import Spacer from "../../components/Spacer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Feedback extends Component {
  state = {
    email: "",
    message: "",
    errorMessage: "",
    alertOpen: false,
    errorOpen: false,
  };

  handleEmailText = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  handleMessageText = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  handleSubmit = (e) => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (this.state.email.length === 0 || !re.test(this.state.email)) {
      this.setState({
        errorMessage: "Please enter a valid email!",
        errorOpen: true,
      });
    } else if (this.state.message.length === 0) {
      this.setState({
        errorMessage: "Message can't be empty!",
        errorOpen: true,
      });
    } else {
      e.preventDefault();
      const messageRef = ref(database, `messages`);
      const newMessageRef = push(messageRef);

      set(newMessageRef, {
        email: this.state.email,
        message: this.state.message,
        timestamp: Date.now(),
      });
      this.setState({
        email: "",
        message: "",
        alertOpen: true,
      });
    }
  };

  render() {
    const titleStyle = {
      fontSize:
        window.innerWidth < 600
          ? "2rem"
          : window.innerWidth < 1024
          ? "2.5rem"
          : "3.1rem",
      fontWeight: "700",
    };
    return (
      <div id="contact-sec" className="section">
        <div id="feedback-sec">
          <img id="feedback-commas" src={commas} alt="commas" />
          <div
            className="row"
            style={{
              justifyContent: "center",
              flexDirection: window.innerWidth < 1024 ? "column" : "row",
              margin: "2vh",
            }}
          >
            <span style={{ ...titleStyle, color: primaryColor }}>
              {" "}
              Make a wish !
            </span>
            <Spacer width={"12px"} />
            <span style={{ ...titleStyle, color: secondaryColor }}>
              We’ll make it happen
            </span>
          </div>
          <form action="" id="contact-form">
            <div id="contact-form-container">
              <div className="row-1-input">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleEmailText}
                  placeholder="Your email address"
                />
              </div>
              <div className="row-2-input">
                <textarea
                  name="message"
                  id="message-area"
                  value={this.state.message}
                  onChange={this.handleMessageText}
                  placeholder="Drop your wish  here"
                ></textarea>
              </div>
              <CustomBtn
                text="SUBMIT"
                onClick={this.handleSubmit}
                width={window.innerWidth < 600 ? "95%" : "40%"}
                padding="10px 0px"
              />
            </div>
          </form>
          <Snackbar
            open={this.state.alertOpen}
            autoHideDuration={3000}
            onClose={() => this.setState({ alertOpen: false })}
          >
            <Alert
              onClose={() => this.setState({ alertOpen: false })}
              severity="success"
              sx={{ width: "100%" }}
            >
              Message sent successfully!
            </Alert>
          </Snackbar>
          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={3000}
            onClose={() => this.setState({ errorOpen: false })}
          >
            <Alert
              onClose={() => this.setState({ errorOpen: false })}
              severity="error"
              sx={{ width: "100%" }}
            >
              {this.state.errorMessage}
            </Alert>
          </Snackbar>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Feedback;
