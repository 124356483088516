import React, { Component } from "react";
import logo from "../assets/header/itribe-logo.svg";

class PrivacyScreen extends Component {
  render() {
    return (
      <div
        style={{
          padding: "8vh 8vw",
        }}
      >
        <div>
          <img src={logo} className="logo" alt="logo" />
        </div>
        <p>
          <br />
        </p>
        <h1>Privacy-policy</h1>
        <p>
          <br />
        </p>
        <p>
          Your privacy matters to Cogrow Ventures Private Limited Private
          Limited (the &ldquo;Company&rdquo;, &ldquo;we&rdquo;,
          &ldquo;iTribe&rdquo;, or &ldquo;us&rdquo;).
          <br />
          <br />
          This Privacy Policy (&quot;Privacy Policy&quot;) describes Company
          policies and procedures on the collection, use, and disclosure of your
          information when you use the Website/Application/Extension. The
          company is committed to ensuring that any use or sharing of your
          information with anyone shall be only in accordance with the
          procedures described in this Privacy Policy. The terms &quot;we&quot;,
          &quot;Company&quot;, &quot;our&quot; and &quot;us&quot; refer to
          Company, and the terms &quot;you&quot; and &quot;your&quot; refer to a
          user of the Website provided by the Company. The term
          &quot;Services&quot; shall refer to the services offered by Company to
          the users through the Website. The capitalized terms used in this
          Privacy Policy, but not defined in this Privacy Policy, shall have the
          meaning given to such terms in the Terms of Service. Please read this
          Privacy Policy before using the Website or submitting any personal
          information to Company.
          <br />
          <br />
        </p>
        <h3>Privacy policy is part of our terms of service</h3>
        <p>
          <br />
        </p>
        <p>
          This Privacy Policy is a part of and incorporated within, and is to be
          read along with, the Terms of Service.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <h3>Your consent&nbsp;</h3>
        <p>
          By using the Website, you agree and consent to the collection, use and
          sharing of your information collected by us in accordance with this
          Privacy Policy.
        </p>
        <p>
          <br />
        </p>
        <h3>Policy changes&nbsp;</h3>
        <p>
          <br />
        </p>
        <p>
          If we change our Privacy Policy, we will post those changes on this
          page. If we make any changes to this Privacy Policy that materially
          change how we treat your personal information, we will endeavor to
          provide you with reasonable notice of such changes, such as via
          prominent notice on the Website or to your email address on record and
          where required by law, we will obtain your consent. Your continued use
          of our Services after we publish or send a notice about our changes to
          this Privacy Policy shall constitute your consent to the updated
          Privacy Policy.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <h3>Competency to contract</h3>
        <p>
          <br />
        </p>
        <p>
          &nbsp;We do not knowingly collect or solicit personal information from
          anyone who is a minor or otherwise incompetent to contract, or
          knowingly allow such persons to register on Company, except without
          parental consent.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <h3>Information we may collect from you&nbsp;</h3>
        <p>
          <br />
        </p>
        <p>We will collect and process the following information about you:</p>
        <ol type={"a"}>
          <li>
            <p>Full Name</p>
          </li>
          <li>
            <p>Date of Birth</p>
          </li>
          <li>
            <p>Email ID</p>
          </li>
          <li>
            <p>Phone Number</p>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          You will be required to give us personal information about yourself by
          filling in your details whilst undertaking the following:&nbsp;
        </p>
        <p>(i) creating an Account, or&nbsp;</p>
        <p>(ii) corresponding with us by phone, e-mail, or&nbsp;</p>
        <p>(iii) subscribing/using our specific Services, or&nbsp;</p>
        <p>(iv) searching for a subject matter, or&nbsp;</p>
        <p>
          (v) participating in the interactive services offered by the Website
          such as discussion boards, competition, promotion or survey, other
          social media functions or make payments etc., or&nbsp;
        </p>
        <p>(vi) troubleshooting any problems.&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          The information you give us will include your name, e-mail address,
          phone number and location details, and may include your personal
          description, photograph, professional registration details,
          qualification, designation, etc. Your name, photograph, personal
          description and any other information that you choose to add to your
          public-facing profile will be available for viewing to other users of
          the Website. Once you create an Account, other users will be able to
          see on your profile certain information about your activity on the
          Website, such as the questions and answers you post, your followers
          and who you follow, topics of interest to you, the information you
          list as credentials, and your edits to Your Content (defined
          below).&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          The Website enables you to add posts, texts, photographs, videos,
          links, and files to share with others. All material that you upload,
          publish or display to others via the Website will be referred to
          collectively as &quot;Your Content&quot;. You acknowledge and agree
          that, as part of using the Website, Your Content may be viewed by the
          general public.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          With regard to each of your visits to the Website, we will
          automatically collect and analyze the following demographic and other
          information:&nbsp;
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <p>
              We collect and store the information and content that you post on
              the Website, including your questions, answers, photographs, and
              comments. Unless you have posted certain content anonymously, Your
              Content, date and time stamps, and all associated comments are
              publicly viewable on the Website, along with your name. This also
              may be indexed by search engines and be republished elsewhere on
              the Internet in accordance with our Terms of Service. For more
              information about what you can change, see the below section on
              Your Rights;&nbsp;
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <p>
              When you communicate with us (via email, phone, through the
              Website or otherwise), we may maintain a record of your
              communication;&nbsp;
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <p>
              The Website includes the Company advertising services (&quot;Ad
              Services&quot;), which may collect user activity and browsing
              history within the Website and across third-party sites and online
              services, including those that include our ad pixels
              (&quot;Pixels&quot;), widgets, plug-ins, buttons, or related
              services. Our Ad Services collect browsing information such
              Internet protocol (IP) address and location, your login
              information, browser type and version, date and time stamp, user
              agent, Company cookie ID (if applicable), time zone setting,
              browser plug-in types and versions, operating system and platform,
              and other information about user activities on the Website, as
              well as on third party sites and services that have embedded our
              Pixels, widgets, plug-ins, buttons, or related services.&nbsp;
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <p>
              Information about your visit, including the full Uniform Resource
              Locators (URL), through and from the Website (including date and
              time); material or information that you viewed or searched for;
              page response times, download errors, length of visits to certain
              pages, page interaction information (such as scrolling, clicks,
              and mouse-overs), and methods used to browse away from the page
              and any phone number used to call our customer service
              number.&nbsp;
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <p>
              If you use your account information with Facebook, Twitter, Google
              Plus or Gmail or any other social networking or similar site
              (collectively, &quot;SNS&quot;) to sign in to the Website, an
              option of which may be provided by the Website to you at its sole
              discretion, you will be allowing us to pass to and receive from
              the SNS your log-in information and other user data. If you elect
              to sign up through an SNS, you may be asked to enable sharing of
              your contacts with the Website. If you agree to this sharing, in
              order to facilitate a more connected experience, we may send
              notices informing your contacts you have joined the Website so
              that you and your contacts can find and follow each other on the
              Website. Please be aware that the specific information we obtain
              from the SNS is dependent on their privacy policies and/or on your
              settings regarding what information you have consented to share.
              You should consult their respective privacy policies for
              information about their practices.&nbsp;
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <h3>Information we receive from other sources.&nbsp;</h3>
        <p>
          <br />
        </p>
        <p>
          We may receive information about you from third parties, such as other
          users, partners (including ad partners, analytics providers, search
          information providers), or our affiliated companies or if you use any
          of the other websites/apps we operate or the other Services we
          provide. Users of our Ad Services and other third-parties may share
          information with us such as a cookie ID, device ID, or demographic or
          interest data, and information about content viewed or actions taken
          on a third party website, online service or app. For example, users of
          our Ad Services may also be able to share customer list information
          (e.g., email or phone number) with us to create customized audience
          segments for their ad campaigns.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <h3>Cookies</h3>
        <p>
          <br />
        </p>
        <p>
          Our Website uses cookies and tracking technology (including pixels)
          depending on the features offered. Cookies are small files that the
          websites place on your computer/device as you browse the web. Like
          many websites, we use cookies to discover how people are using our
          services and to make them work better. We do use cookies to store
          information, such as your personal preferences when you visit our
          Website. This could include only showing you a popup once in your
          visit, or the ability to login to some of our features, such as
          forums. For more detailed information about these mechanisms and how
          we collect activity information, see our Cookie Policy.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <h3>Uses of your Information&nbsp;</h3>
        <p>
          <br />
        </p>
        <p>
          We will use your personal information and any other information
          collected by us:&nbsp;
        </p>
        <p>
          To carry out our obligations arising from any contracts entered into
          between you and us and to provide you with the relevant information
          and Services;&nbsp;
        </p>
        <p>
          To administer and enhance the security of our Website and for internal
          operations, including troubleshooting, data analysis, testing,
          research, statistical and survey purposes;&nbsp;
        </p>
        <ul>
          <li>
            <p>
              To remember information to help you efficiently access your
              Account;&nbsp;
            </p>
          </li>
          <li>
            <p>
              To provide you, or permit selected third parties to provide you,
              with information about services we consider similar to those that
              you are already using, or have enquired about, or may interest
              you. If you are a registered user, we (or they) will contact you
              by electronic means (e-mail or SMS or telephone) with information
              about these services;&nbsp;
            </p>
          </li>
          <li>
            <p>
              To ensure that Content from our Website is presented in the most
              effective manner;&nbsp;
            </p>
          </li>
          <li>
            <p>
              To send you Service-related notices (including regarding any
              change to the Services) and other promotional material. In order
              to verify that the e-mail address provided by you belongs to you,
              we may send you a verification e-mail. Once, the verification
              process is complete, your Account will be fully functional;
            </p>
          </li>
          <li>
            <p>
              To allow you to participate in interactive features of our
              Services, if any, when you choose to do so;&nbsp;
            </p>
          </li>
          <li>
            <p>
              To measure or understand the effectiveness of advertising we serve
              to you and others, and to deliver relevant advertising to you.
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          &nbsp;Further, we may combine the information that we receive from
          third parties with the information you give to us and information we
          collect about you for the purposes set out above.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          We analyze the log files of our Website to better understand the
          volume of traffic to particular areas of our Website. This information
          helps us to serve your information needs.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h3>Disclosure and Distribution of Your Information</h3>
        <p>
          <br />
        </p>
        <p>
          We reserve the right to distribute your personal information to any
          third party only under one of the following circumstances.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <h3>For Crime Prevention or Investigation.&nbsp;</h3>
        <p>
          <br />
        </p>
        <p>
          We may share this information with governmental agencies or other
          companies assisting us when we are:&nbsp;
        </p>
        <ul>
          <li>
            <p>
              Obligated under the applicable laws or in good faith to respond to
              court orders and processes; or&nbsp;
            </p>
          </li>
          <li>
            <p>
              Detecting and preventing against the actual or potential
              occurrence of identity theft, fraud, abuse of Services and other
              illegal acts;&nbsp;
            </p>
          </li>
          <li>
            <p>
              Responding to claims that an advertisement, posting or other
              content violates the intellectual property rights of a third
              party;&nbsp;
            </p>
          </li>
          <li>
            <p>
              Under a duty to disclose or share your personal data in order to
              enforce or apply our Terms of Service and other agreements; or to
              protect the rights, property, or safety of the Company, our
              customers, or others. This includes exchanging information with
              other companies and organizations for the purposes of fraud
              protection and credit risk reduction.&nbsp;
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <h3>For Internal Use.&nbsp;</h3>
        <p>
          <br />
        </p>
        <p>
          We may share this information with any member of our Group. The term
          &quot;Group&quot; includes means, with respect to any person, any
          entity that is controlled by such person, or any entity that controls
          such person, or any entity that is under common control with such
          person, whether directly or indirectly, or, in the case of a natural
          person, any Relative (as such term is defined in the Companies Act,
          1956 and Companies Act, 2013 to the extent applicable) of such
          person.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <h3>Data Security Precautions&nbsp;</h3>
        <p>
          <br />
        </p>
        <p>
          We have in place appropriate technical and security measures to
          prevent unlawful access to or accidental loss of information
          collected. All information you provide to us is stored on our secure
          servers. We use firewalls on our servers. You are advised not to send
          your full credit/debit card details through unencrypted electronic
          platforms. Where we have given you (or where you have chosen) a
          username and password which enables you to access certain parts of our
          Website, you are responsible for keeping these details confidential.
          We ask you not to share a password with anyone. You must log out when
          you leave any section of our Website protected by a password. We
          recommend closing the browser completely and re-opening it before
          accessing other websites over the internet.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your data
          transmitted through the Website; any transmission is at your own risk.
          Once we have received your information, we will use strict physical,
          electronic, and procedural safeguards to try to prevent unauthorized
          access.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <h3>Your Rights&nbsp;</h3>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <p>
              You may choose to restrict the collection or use of your personal
              information in the following ways:&nbsp;
            </p>
          </li>
          <li>
            <p>
              You have the right to ask us not to process your personal data for
              direct marketing purposes by sending us an email at
              product@itribe.in, in which case we may not be able to provide
              certain or all the Services to you.&nbsp;
            </p>
          </li>
          <li>
            <p>
              You may correct, amend, add or delete personal information from
              your Account at any time by logging in and visiting your profile
              page.&nbsp;
            </p>
          </li>
          <li>
            <p>
              You are free to remove or delete Account at any point in time. If
              you choose to delete your Account information, then your Account
              will be removed from public visibility on the Website, and it may
              not be restored by us, however, Your Content shall be available on
              the Website and/or any other platform where it has been shared,
              linked or referred.&nbsp;
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h3>Website Security and Grievance Officer&nbsp;</h3>
        <p>
          <br />
        </p>
        <p>About our website security</p>
        <p>
          <br />
        </p>
        <p>
          Grievance Officer in accordance with Information Technology Act, 2000
          and Information Technology (Intermediaries Guidelines) Rules, 2011
          made thereunder, the name and contact details of the Grievance Officer
          are provided below:
        </p>
        <p>
          <br />
        </p>
        <p>Manikanta Nikhil,</p>
        <p>nikhil@itribe.in</p>
        <p>
          <br />
        </p>
        <p>
          If you come across any abuse or violation of the Privacy Policy,
          please report to product@itribe.in.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>Privacy Contact Information&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          If you have any queries, concerns, or comments with regards to
          anything contained in our Privacy Policy, you may contact us by
          sending an email to product@itribe.in. You may also contact us at the
          following address:&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>H NO 8-1-8/8/204, OLD BWNP, Hyderabad, Telangana, India - 500011</p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </div>
    );
  }
}

export default PrivacyScreen;
