import React from "react";
import { primaryColor, secondaryColor } from "../utils/constants";

const iconStyle = {
  height: "60%",
  width: "60%",
};

const circleBase = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  width: window.innerWidth < 1024 ? "50px" : "80px",
  height: window.innerWidth < 1024 ? "50px" : "80px",
  background: "#362754",
};
const circleBorder = {
  display: "flex",
  margin: "0 2rem auto 0",
  justifyContent: "center",
  borderRadius: "50%",
  padding: "1px",
  width: window.innerWidth < 1024 ? "50px" : "80px",
  height: window.innerWidth < 1024 ? "50px" : "80px",
  position: "relative",
  alignItems: "center",
  background:
    "radial-gradient(200% 200% at 6.25% 2.17%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.15) 30.21%, rgba(255, 255, 255, 0.7) 75%, rgba(255, 255, 255, 0) 100%)",
};

const containerStyle = {
  display: "flex",
  textAlign: "left",
  justifyContent: "left",
  alignItems: "center",
  marginBottom: "1.5rem",
};
const titleStyle = {
  color: primaryColor,
  fontWeight: "700",
  fontSize: window.innerWidth < 600 ? "1.25rem" : "1.6rem",
};
const descriptionStyle = {
  color: secondaryColor,
  fontSize: window.innerWidth < 600 ? "1rem" : "1.4rem",
};

const FeatureItem = (props) => {
  return (
    <div className="feat-item-container" style={containerStyle}>
      <div style={circleBorder}>
        <div className="circleBase" style={circleBase}>
          <img
            src={props.icon}
            height="30px"
            style={iconStyle}
            className="icon-features"
            alt="finances icon"
          />
        </div>
      </div>
      <div className="feat-content">
        <span style={titleStyle}>{props.title} </span>
        <br />
        <span style={descriptionStyle}>{props.text}</span>
      </div>
    </div>
  );
};

export default FeatureItem;
