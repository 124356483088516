import React from "react";
import logo from "../../assets/header/itribe-logo.svg";
import heart from "../../assets/heart.png";
import fb from "../../assets/footer/fb.svg";
import linkedin from "../../assets/footer/linkedin.svg";
import youtube from "../../assets/footer/youtube.svg";
import twitter from "../../assets/footer/twitter.svg";
import insta from "../../assets/footer/insta.svg";
import telegram from "../../assets/footer/telegram.svg";
import "./Footer.css";
import { Navigate } from "../../utils/navigator";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-row-1">
        <div id="footer-wrapper-1">
          <div className="footer-logo">
            <a href="./#">
              <img src={logo} className="footer-logo-img" alt="logo" />
            </a>
          </div>
          <div className="footer-social-icons">
            <a
              href="https://www.instagram.com/itribe_official/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} className="footer-icon" alt="insta" />
            </a>
            <a
              href="https://www.linkedin.com/company/investorstribe/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} className="footer-icon" alt="linkedin" />
            </a>

            <a
              href="https://twitter.com/itribe_"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} className="footer-icon" alt="twitter" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCF7bd0Xzp3D2sSukBtjQGhQ"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} className="footer-icon" alt="youtube" />
            </a>
            <a
              href="https://www.facebook.com/InvestorsTribe/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fb} className="footer-icon" alt="fb" />
            </a>
            <a href="https://t.me/+w3OuB-Purt0yNjll">
              <img src={telegram} className="footer-icon" alt="telegram" />
            </a>
          </div>
        </div>
        <div id="footer-wrapper-2">
          <div
            onClick={() => {
              document.location.href = Navigate.TNCScreen;
            }}
            className="footer-links"
          >
            Terms and Conditions
          </div>

          <div
            onClick={() => {
              document.location.href = Navigate.PrivacyPolicyScreen;
            }}
            className="footer-links"
          >
            {" "}
            Privacy policy
          </div>
          <div
              onClick={() => {
                document.location.href = Navigate.TradingView;
              }}
              className="footer-links"
          >
            {" "}
            TradingView
          </div>
          <div
            onClick={() => {
              document.location.href = Navigate.RefundPolicy;
            }}
            className="footer-links"
          >
            {" "}
            Refund policy
          </div>
          <div
            onClick={() => {
              document.location.href = Navigate.Contact;
            }}
            className="footer-links"
          >
            {" "}
            Contact us
          </div>
        </div>
      </div>
      <div className="footer-row-2">
        <div>
          &copy; Cogrow Ventures Private Limited, 2023, All Rights Reserved
        </div>
        <div>
          Built with{" "}
          <span className="footer-span-2">
            <img src={heart} className="footer-heart-icon" alt="heart" />
          </span>{" "}
          by <span className="footer-span-1">iTribe</span>
        </div>
      </div>
    </div>
  );
};
export default Footer;
