import React, { Component } from "react";
import logo from "../assets/header/itribe-logo.svg";

class RefundScreen extends Component {
  render() {
    return (
      <div
        style={{
          padding: "8vh 8vw",
        }}
      >
        <div>
          <img src={logo} className="logo" alt="logo" />
        </div>
        <p>
          <br />
        </p>
        <h1>Refund and Cancellation Policy</h1>
        <br />
        <p>
          We have put sweat and blood to keep the quality of the content very
          high. Still if users believe that the content is not upto the mark,
          users can write to refunds@itribe.in and discuss the case with the
          team.
        </p>
      </div>
    );
  }
}

export default RefundScreen;
