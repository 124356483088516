import React from "react";

const Avatar = (props) => {
  const avatarStyle = {
    height: props.diameter,
    width: props.diameter,
    borderRadius: "50%",
    position: props.position ?? "auto",
    top: props.top,
    left: props.left,
    padding: props.padding,
    objectFit: "cover",
    backgroundColor: props.bgcolor,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    zIndex: props.zIndex,
  };
  const imgStyle = {
    height: props.diameter,
    width: props.diameter,
    borderRadius: "50%",
  };
  return (
    <div style={avatarStyle} className={props.className}>
      <img style={imgStyle} src={props.img} alt="avatar" />
    </div>
  );
};
export default Avatar;
