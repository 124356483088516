import React, { Component } from "react";
import logo from "../assets/header/itribe-logo.svg";
class TNCScreen extends Component {
  render() {
    return (
      <div style={{ padding: "8vh 8vw" }}>
        <div>
          <img src={logo} className="logo" alt="logo" />
        </div>
        <h1>Terms and Conditions&nbsp;</h1>
        <p>
          Please read these terms and conditions (&quot;terms and
          conditions&quot;, &quot;terms&quot;) carefully before using&nbsp;
          <a href="https://itribe.in">https://itribe.in</a> website
          (&ldquo;website&rdquo;, &quot;service&quot;) operated by Cogrow
          Ventures Private Limited (&quot;us&quot;, &apos;we&quot;,
          &quot;our&quot;, &quot;iTribe&quot;).
        </p>
        <h3>Conditions of use</h3>
        <p>
          By using this website, you certify that you have read and reviewed
          this Agreement and that you agree to comply with its terms. If you do
          not want to be bound by the terms of this Agreement, you are advised
          to leave the website accordingly. Cogrow Ventures Private Limited only
          grants use and access of this website, its products, and its services
          to those who have accepted its terms.
        </p>
        <h3>Privacy policy</h3>
        <p>
          Before you continue using our website, we advise you to read our
          privacy policy&nbsp;
        </p>
        <p>
          <a href="https://itribe.in/privacy_policy">
            https://itribe.in/privacy_policy
          </a>{" "}
          regarding our user data collection. It will help you better understand
          our practices.
        </p>
        <h3>Age restriction</h3>
        <p>
          You must be at least 18 (eighteen) years of age before you can use
          this website. By using this website, you warrant that you are at least
          18 years of age and you may legally adhere to this Agreement. Cogrow
          Ventures Private Limited assumes no responsibility for liabilities
          related to age misrepresentation.
        </p>
        <h3>Intellectual property</h3>
        <p>
          You agree that all materials, products, and services provided on this
          website are the property of Cogrow Ventures Private Limited, its
          affiliates, directors, officers, employees, agents, suppliers, or
          licensors including all copyrights, trade secrets, trademarks,
          patents, and other intellectual property. You also agree that you will
          not reproduce or redistribute the Cogrow Ventures Private
          Limited&rsquo;s intellectual property in any way, including
          electronic, digital, or new trademark registrations.
        </p>
        <p>
          You grant Cogrow Ventures Private Limited a royalty-free and
          non-exclusive license to display, use, copy, transmit, and broadcast
          the content you upload and publish. For issues regarding intellectual
          property claims, you should contact the company in order to come to an
          agreement.
        </p>
        <h3>User accounts</h3>
        <p>
          As a user of this website, you may be asked to register with us and
          provide private information. You are responsible for ensuring the
          accuracy of this information, and you are responsible for maintaining
          the safety and security of your identifying information. You are also
          responsible for all activities that occur under your account or
          password.
        </p>
        <p>
          If you think there are any possible issues regarding the security of
          your account on the website, inform us immediately so we may address
          them accordingly.
        </p>
        <p>
          We reserve all rights to terminate accounts, edit or remove content
          and cancel orders at our sole discretion.
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h3>Use of Website;&nbsp;</h3>
        <p>
          Cogrow Ventures Private Limited is not responsible for any damages
          resulting from use of this website by anyone. You will not use the
          Website for illegal purposes. You will (1) abide by all applicable
          local, state, national, and international laws and regulations in your
          use of the Website (including laws regarding intellectual property),
          (2) not interfere with or disrupt the use and enjoyment of the Website
          by other users, (3) not resell material on the Website, (4) not
          engage, directly or indirectly, in transmission of &quot;spam&quot;,
          chain letters, junk mail or any other type of unsolicited
          communication, and (5) not defame, harass, abuse, or disrupt other
          users of the Website
        </p>
        <h3>License</h3>
        <p>
          By using this Website, you are granted a limited, non-exclusive,
          non-transferable right to use the content and materials on the Website
          in connection with your normal, noncommercial, use of the Website. You
          may not copy, reproduce, transmit, distribute, or create derivative
          works of such content or information without express written
          authorization from Cogrow Ventures Private Limited or the applicable
          third party (if third party content is at issue).
        </p>
        <h3>Posting&nbsp;</h3>
        <p>
          By posting, storing, or transmitting any content on the Website, you
          hereby grant Cogrow Ventures Private Limited a perpetual, worldwide,
          non-exclusive, royalty-free, assignable, right and license to use,
          copy, display, perform, create derivative works from, distribute, have
          distributed, transmit and assign such content in any form, in all
          media now known or hereinafter created, anywhere in the world. Cogrow
          Ventures Private Limited does not have the ability to control the
          nature of the user-generated content offered through the Website. You
          are solely responsible for your interactions with other users of the
          Website and any content you post. Cogrow Ventures Private Limited is
          not liable for any damage or harm resulting from any posts by or
          interactions between users. Cogrow Ventures Private Limited reserves
          the right, but has no obligation, to monitor interactions between and
          among users of the Website and to remove any content Cogrow Ventures
          Private Limited deems objectionable.&nbsp;
        </p>
        <h3>Applicable law</h3>
        <p>
          By visiting this website, you agree that the laws of India, without
          regard to principles of conflict laws, will govern these terms and
          conditions, or any dispute of any sort that might come between Cogrow
          Ventures Private Limited and you, or its business partners and
          associates.
        </p>
        <h3>Disputes</h3>
        <p>
          Any dispute related in any way to your visit to this website or to
          products you purchase from us shall be arbitrated by state or federal
          court India &nbsp;and you consent to exclusive jurisdiction and venue
          of such courts.
        </p>
        <h3>Disclaimer and Warranties</h3>
        <p>
          Your use of this website and/or products are at your sole risk. The
          website and products are offered on an &quot;as is&quot; and &quot;as
          available&quot; basis. Cogrow Ventures Private Limited expressly
          disclaims all warranties of any kind, whether express or implied,
          including, but not limited to, implied warranties of merchantability,
          fitness for a particular purpose and non-infringement with respect to
          the products or website content, or any reliance upon or use of the
          website content or products. (&quot;products&quot; include trial
          products.)
        </p>
        <p>
          Without limiting the generality of the foregoing, Cogrow Ventures
          Private Limited makes no warranty:&nbsp;
        </p>
        <p>
          That the information provided on this website is accurate, reliable,
          complete, or timely.
        </p>
        <p>
          That the links to third-party websites are to information that is
          accurate, reliable, complete, or timely.
        </p>
        <p>
          No advice or information, whether oral or written, obtained by you
          from this website will create any warranty not expressly stated
          herein.&nbsp;
        </p>
        <p>
          As to the results that may be obtained from the use of the products or
          that defects in products will be corrected.&nbsp;
        </p>
        <p>Regarding any products purchased or obtained through the website.</p>
        <p>
          Some jurisdictions do not allow the exclusion of certain warranties,
          so some of the above exclusions may not apply to you.
        </p>
        <h3>Indemnification</h3>
        <p>
          You agree to indemnify Cogrow Ventures Private Limited and its
          affiliates and hold Cogrow Ventures Private Limited harmless against
          legal claims and demands that may arise from your use or misuse of our
          services. We reserve the right to select our own legal counsel.&nbsp;
        </p>
        <h3>Limitation on liability</h3>
        <p>
          Cogrow Ventures Private Limited is not liable for any damages that may
          occur to you as a result of your misuse of our website.
        </p>
        <p>
          Cogrow Ventures Private Limited reserves the right to edit, modify,
          and change this Agreement at any time. We shall let our users know of
          these changes through electronic mail. This Agreement is an
          understanding between Cogrow Ventures Private Limited and the user,
          and this supersedes and replaces all prior agreements regarding the
          use of this website.
        </p>
        <h3>General</h3>
        <h3>Force Majeure.&nbsp;</h3>
        <p>
          Cogrow Ventures Private Limited will not be deemed in default
          hereunder or held responsible for any cessation, interruption or delay
          in the performance of its obligations hereunder due to earthquake,
          flood, fire, storm, natural disaster, act of God, war, terrorism,
          armed conflict, labor strike, lockout, or boycott.
        </p>
        <h3>Cessation of Operation.&nbsp;</h3>
        <p>
          Cogrow Ventures Private Limited may at any time, in its sole
          discretion and without advance notice to you, cease operation of the
          Website and distribution of the Products.
        </p>
        <h3>Entire Agreement&nbsp;</h3>
        <p>
          This Agreement comprises the entire agreement between you and Cogrow
          Ventures Private Limited and supersedes any prior agreements
          pertaining to the subject matter contained herein.
        </p>
        <h3>Effect of Waiver</h3>
        <p>
          The failure of Cogrow Ventures Private Limited to exercise or enforce
          any right or provision of this Agreement will not constitute a waiver
          of such right or provision. If any provision of this Agreement is
          found by a court of competent jurisdiction to be invalid, the parties
          nevertheless agree that the court should endeavor to give effect to
          the parties&apos; intentions as reflected in the provision, and the
          other provisions of this Agreement remain in full force and effect.
        </p>
      </div>
    );
  }
}

export default TNCScreen;
