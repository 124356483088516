import React, { Component } from "react";
import playstore from "../../assets/icons/playstore.svg";
import appstore from "../../assets/icons/appstore.svg";
import chromeExtension from "../../assets/icons/chromeExtension.svg";
import leftQuote from "../../assets/hero/left-quote.svg";
import rightQuote from "../../assets/hero/right-quote.svg";
import "./Header.css";
import { database } from "../../firebaseConfig";
import { ref, set, get } from "firebase/database";
import Illustration from "../../components/Illustration";
import Navbar from "../../components/Navbar";
import { primaryColor } from "../../utils/constants";

class Header extends Component {
  state = {
    email: "",
    alertOpen: false,
    errorOpen: false,
  };

  emailToKey(emailAddress) {
    return emailAddress.replace(/[.]/g, "%20");
  }

  handleEmailText = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleSubmit = (e) => {
    let counter = 0;
    const email = this.state.email;

    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (email.length !== 0 && re.test(email)) {
      const uid = this.emailToKey(email);
      const waitlistRef = ref(database, "waitlist/" + uid);

      get(waitlistRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const res = snapshot.val();
            counter = res["counter"];
          }
          set(waitlistRef, {
            email: this.state.email,
            timestamp: Date.now(),
            counter: counter + 1,
          });
          this.setState({
            alertOpen: true,
            email: "",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.setState({
        errorOpen: true,
      });
    }
  };

  render() {
    let headerStyle = {
      fontSize:
        window.innerWidth < 600
          ? "3.5rem"
          : window.innerWidth < 1300
          ? "5.5rem"
          : "6rem",
      color: primaryColor,
    };
    let leftQuoteStyle = {
      position: "absolute",
      top:
        window.innerWidth < 600
          ? "-0.4rem"
          : window.innerWidth < 1300
          ? "-0.1rem"
          : "-0.2rem",
      left: "-1.1rem",
      scale: window.innerWidth < 600 ? "0.7" : "1",
    };
    let rightQuoteStyle = {
      position: "absolute",
      top:
        window.innerWidth < 600
          ? "-0.4rem"
          : window.innerWidth < 1300
          ? "-0.1rem"
          : "-0.2rem",
      right: "-1rem",
      scale: window.innerWidth < 600 ? "0.7" : "1",
    };
    return (
      <div id="header-sec" className="section">
        <Navbar />
        <div container className="header-container">
          <div id="header-content-box">
            <div className="header-wrapper">
              <span className="header-text">Finance Nahi</span>
              <div className="row" id="funance-row">
                <div
                  className="row"
                  style={{ position: "relative", marginLeft: "15px" }}
                >
                  <div style={leftQuoteStyle}>
                    <img src={leftQuote} alt="left-quote" />
                  </div>
                  <span className="header-text" style={headerStyle}>
                    FUN
                  </span>
                  <div style={rightQuoteStyle}>
                    <img src={rightQuote} alt="right-quote" />
                  </div>
                </div>
                <span className="header-text" style={headerStyle}>
                  ance
                </span>
              </div>
              <div className="store-row">
                <a
                  className="store-btn"
                  id="playstore-btn"
                  href="https://play.google.com/store/apps/details?id=com.itribe.tralkerapp&referrer=utm_source%3DWebsite%26utm_medium%3DWebsite%26utm_campaign%3DWebsite"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={playstore} alt="playstore button" />
                </a>
                <a
                  className="store-btn"
                  id="appstore-btn"
                  href="https://apps.apple.com/in/app/itribe/id1598267902"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appstore} alt="appstore button" />
                </a>
                <a
                  className="store-btn"
                  id="chrome-extension-btn"
                  href="https://chrome.google.com/webstore/detail/itribe-live-stock-market/lpkeebafkmjhfgldgdignoohimpopbce"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    id="chrome-ext-img"
                    src={chromeExtension}
                    alt="chrome extension button"
                  />
                </a>
              </div>
            </div>
          </div>
          <div id="header-illustration-box">
            <div className="content-glow" />
            <Illustration />
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
