import React, { useEffect, useState } from "react";
import post1 from "../../../assets/features/post-1.png";
import post2 from "../../../assets/features/post-2.png";
import post3 from "../../../assets/features/post-3.png";

const FeaturePosts = (props) => {
  const [currIndex, updateIndex] = useState(0);
  const [pause, updatePause] = useState(false);
  const primaryTitles = ["Expert Trading", "Market News", "Flash News"];
  const secondaryTitles = ["Advices", "in 60 Words", "with Live Tracking"];
  const posts = [post1, post2, post3];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!pause) {
        updateIndex((currIndex + 1) % 3);
      }
    }, 3000);
    return () => {
      // cleaning up timers
      clearInterval(interval);
    };
  });
  const title1Style = {
    fontSize:
      window.innerWidth < 600
        ? "1.4rem"
        : window.innerWidth < 1300
        ? "1.7rem"
        : "2rem",
  };
  const title2Style = {
    fontSize:
      window.innerWidth < 600
        ? "2rem"
        : window.innerWidth < 1300
        ? "3rem"
        : "4rem",
  };
  const circleStyle = {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
  };

  const postWrapperStyle = {
    zIndex: "1",
    width: window.innerWidth < 600 ? "100%" : "auto",
    position: "relative",
    height: "100%",
  };
  const postImgStyle = {
    width: "100%",
    transition: "opacity 500ms, transform 500ms",
  };
  const contentWrapperStyle = {
    width: window.innerWidth < 600 ? "100%" : "40vw",
    alignItems: window.innerWidth < 600 ? "center" : "left",
  };
  const circleBase = {
    position: "absolute",
    height: "30vw",
    width: "30vw",
    justifyContent: "center",
    borderRadius: "50%",
    background:
      "conic-gradient(from 180deg at 50% 50%, #1BBAFF 0deg, #00A3FF 148.13deg, #6D3BA7 240deg, #7431A7 360deg)",
    filter: "blur(106.827px)",
    transform: "rotate(-29.54deg)",
    zIndex: "-1",
  };

  return (
    <div className="row" id="feature-posts-wrapper-style">
      <div
        onMouseOver={() => updatePause(true)}
        onMouseOut={() => updatePause(false)}
        style={postWrapperStyle}
      >
        <div style={circleBase}></div>
        <div id="feat-img-container">
          <img style={postImgStyle} src={posts[currIndex]} alt="feature-post" />
        </div>
      </div>
      <div
        onMouseOver={() => updatePause(true)}
        onMouseOut={() => updatePause(false)}
        className="col"
        style={contentWrapperStyle}
      >
        <span style={title1Style}>Stay informed with</span>
        <span style={title2Style} className="primary-title-style">
          {primaryTitles[currIndex]}
        </span>
        <span style={title2Style} className="secondary-title-style">
          {secondaryTitles[currIndex]}
        </span>
        <div
          className="row"
          style={{
            width:
              window.innerWidth < 600
                ? "20vw"
                : window.innerWidth < 1024
                ? "10vw"
                : "6vw",
            marginTop: "5vh",
            justifyContent: "space-between",
          }}
        >
          <div
            onClick={() => updateIndex(0)}
            style={{
              ...circleStyle,
              background: currIndex === 0 ? "white" : "rgba(255,255,255,0.3)",
            }}
          />
          <div
            onClick={() => updateIndex(1)}
            style={{
              ...circleStyle,
              background: currIndex === 1 ? "white" : "rgba(255,255,255,0.3)",
            }}
          />
          <div
            onClick={() => updateIndex(2)}
            style={{
              ...circleStyle,
              background: currIndex === 2 ? "white" : "rgba(255,255,255,0.3)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturePosts;
