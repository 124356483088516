import React, { Component } from "react";
import logo from "../assets/header/itribe-logo.svg";

class ContactScreen extends Component {
  render() {
    return (
      <div
        style={{
          padding: "8vh 8vw",
        }}
      >
        <div>
          <img src={logo} className="logo" alt="logo" />
        </div>
        <p>
          <br />
        </p>
        <h1>Contact Us</h1>
        <br />
        <p>Cogrow Ventures Private Limited</p>

        <p>Emailid - feedback@itribe.in</p>

        <p>Phone Number - +91-9008301297</p>

        <p>
          Address: 5th Main Rd, 7th Sector, HSR Layout, Bengaluru, Karnataka
          560034
        </p>
      </div>
    );
  }
}

export default ContactScreen;
