import React from "react";

const MobileMockup = (props) => {
  const mobileWrapper = {
    display: "flex",
    position: "relative",
    height: window.innerWidth < 600 ? "50vh" : "100%",
    justifyContent: "center",
  };
  const circleBase = {
    display: "flex",
    position: "absolute",
    margin: "auto",
    justifyContent: "center",
    borderRadius: "50%",
    background:
      "conic-gradient(from 180deg at 50% 50%, #1BBAFF 0deg, #00A3FF 148.13deg, #6D3BA7 240deg, #7431A7 360deg)",
    filter: "blur(129.597px)",
    transform: "rotate(-29.54deg)",
    zIndex: "0",
  };
  const mobileStyle = {
    width: "100%",
    justifyContent: "right",
    zIndex: "1",
  };

  return (
    <div id={props.id} style={mobileWrapper}>
      <div id="circle-base" style={circleBase}></div>
      <div className="row" style={mobileStyle}>
        <img
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
          src={props.img}
          alt="mobile screen"
        />
      </div>
    </div>
  );
};

export default MobileMockup;
