import illustration from "../assets/hero/illustration.png";

const Illustration = () => {
  return (
    <div id="illustration-container">
      <img src={illustration} className="illustration" alt="illustration" />
    </div>
  );
};

export default Illustration;
