import React from "react";
import "./Feature.css";
import brand1 from "../../assets/features/zerodha.svg";
import brand2 from "../../assets/features/upstox.svg";
import brand3 from "../../assets/features/grow.svg";
import brand4 from "../../assets/features/angel-one.svg";
import brand5 from "../../assets/features/tradingview.svg";
import Spacer from "../../components/Spacer";
import { primaryColor, secondaryColor } from "../../utils/constants";
import FeaturePosts from "./components/Feature-Component";

const Feature = () => {
  return (
    <div id="feature-sec" className="section">
      <FeaturePosts />
      <div id="brand-col">
        <div
          className="row"
          style={{
            justifyContent: "center",
            flexDirection: window.innerWidth < 600 ? "column" : "row",
          }}
        >
          <span className="brand-header" style={{ color: secondaryColor }}>
            Link Your Portfolio for
          </span>
          <Spacer width={"8px"} />
          <span className="brand-header" style={{ color: primaryColor }}>
            Personalised Alerts
          </span>
        </div>
        <div id="brand-row">
          <div className="brand-item">
            <img src={brand1} alt="brand logo" />
          </div>
          <div className="brand-item">
            <img src={brand2} alt="brand logo" />
          </div>
          <div className="brand-item">
            <img src={brand3} alt="brand logo" />
          </div>
          <div className="brand-item">
            <img src={brand4} alt="brand logo" />
          </div>
          <div className="brand-item">
            <img src={brand5} alt="brand logo" />
          </div>
          <div className="brand-item">
            <p id="and-more">and more....</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
