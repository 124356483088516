import React from "react";
import "../feature/Feature.css";
import "./Learn.css";
import "../../index.css";
import FeatureItem from "../../components/FeatureItem";
import icon1 from "../../assets/learn/feat-icon1.svg";
import icon2 from "../../assets/learn/feat-icon2.svg";
import icon3 from "../../assets/learn/feat-icon3.svg";
import mobile1 from "../../assets/learn/mobile1.png";
import MobileMockup from "../../components/MobileMockup";
import Spacer from "../../components/Spacer";

const Learn = () => {
  return (
    <div id="learn-sec" className="section">
      <div className="row" id="learn-container">
        <div id="mobile-mockup-1">
          <MobileMockup
            color="#FEB701"
            rotate="-9.14"
            id="mobile-img-1"
            img={mobile1}
            marginTop={window.innerWidth < 1025 ? "2rem" : "4rem"}
          />
        </div>
        <div id="learn-box">
          <div id="learn-heading" className="row">
            <span className="secondary-title-style">Finance </span>
            <Spacer width="15px" />
            <span className="primary-title-style">Simplified</span>
          </div>
          <div>
            <FeatureItem
              icon={icon1}
              title="5-Minute Finance"
              text="Binge on our bite-sized lessons"
            />
          </div>
          <div>
            <FeatureItem
              icon={icon2}
              title="Stories that Teach"
              text="Like comics? Learn with our Chaiwala & friends!"
            />
          </div>
          <div>
            <FeatureItem
              icon={icon3}
              title="Get Market-Ready"
              text="Insights you can apply in the real world"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Learn;
