import kunalShah from "../assets/investors/kunalShah.png";
import incisive from "../assets/investors/incisive.png";
import planB from "../assets/investors/planB.png";
import eximius from "../assets/investors/eximius.png";
import gauravDahake from "../assets/investors/gauravDahake.png";
import swami from "../assets/investors/swami.png";
import harish from "../assets/investors/harish.png";
import ketan from "../assets/investors/ketan.png";
import srinivas from "../assets/investors/srinivas.png";
import reeju from "../assets/investors/reeju.png";
import aravind from "../assets/investors/aravind.png";
import pavan from "../assets/investors/pavan.png";
import rishi from "../assets/investors/rishi.png";
import aditya from "../assets/investors/aditya.png";
import ravi from "../assets/investors/ravi.png";
import siddartha from "../assets/investors/siddartha.png";
import harsh from "../assets/investors/harsh.png";
import saiKiran from "../assets/investors/saiKiran.png";
import naveen from "../assets/investors/naveen.png";
import achyuth from "../assets/investors/achyuth.png";
import ramana from "../assets/investors/ramana.png";
import lekhaj from "../assets/investors/lekhaj.png";
import silus from "../assets/investors/silus.png";
import rakesh from "../assets/investors/rakesh.png";
import clingingStarsLLP from "../assets/investors/clingingStarsLLP.png";

const investorsList = [
  {
    name: "Kunal Shah",
    img: kunalShah,
    link: "https://www.linkedin.com/in/kunalshah1/",
  },
  {
    name: "Incisive Ventures",
    img: incisive,
    link: "https://incisive.vc/",
  },
  {
    name: "PlanB Capital",
    img: planB,
    link: "https://planbcapital.co/",
  },
  {
    name: "Eximius Ventures",
    img: eximius,
    link: "https://eximiusvc.com/",
  },
  {
    name: "Gaurav Dahake",
    img: gauravDahake,
    link: "https://www.linkedin.com/in/gauravdahake/",
  },
  {
    name: "Swamy",
    img: swami,
    link: "https://www.linkedin.com/in/sswamy/",
  },
  {
    name: "Harish",
    img: harish,
    link: "https://www.linkedin.com/in/hsivaramakrishnan/",
  },
  {
    name: "Ketan",
    img: ketan,
    link: "https://www.linkedin.com/in/ketanjogani/",
  },
  {
    name: "Srinivas",
    img: srinivas,
    link: "https://www.linkedin.com/in/srinivasiyengar/",
  },
  {
    name: "Reeju",
    img: reeju,
    link: "https://www.linkedin.com/in/reejudatta/",
  },
  {
    name: "Aravind",
    img: aravind,
    link: "https://www.linkedin.com/in/aravind-sanka-8a876045/",
  },
  {
    name: "Pavan",
    img: pavan,
    link: "https://www.linkedin.com/in/pavan-guntupalli-1ab52929/",
  },
  {
    name: "Rishi",
    img: rishi,
    link: "https://www.linkedin.com/in/rishikeshsr/",
  },
  {
    name: "Aditya",
    img: aditya,
    link: "https://www.linkedin.com/in/aditya-patimalla-430253155/",
  },
  {
    name: "Ravi",
    img: ravi,
    link: "https://www.linkedin.com/in/yvravikiran/",
  },
  {
    name: "Siddartha",
    img: siddartha,
    link: "https://www.linkedin.com/in/siddarthapolisetty/",
  },
  {
    name: "Harsh",
    img: harsh,
    link: "https://www.linkedin.com/in/harshjm1991/",
  },
  {
    name: "Sai Kiran",
    img: saiKiran,
    link: "https://www.linkedin.com/in/sai-kiran-gorthi-9a3a5617/",
  },
  {
    name: "Naveen",
    img: naveen,
    link: "https://www.linkedin.com/in/naveenchennala/",
  },
  {
    name: "Achyuth",
    img: achyuth,
    link: "https://www.linkedin.com/in/achyuth-reddy-racharla-93620215/",
  },
  {
    name: "Ramana",
    img: ramana,
    link: "https://www.linkedin.com/in/13dec1990/",
  },
  {
    name: "Lekhaj",
    img: lekhaj,
    link: "https://www.linkedin.com/in/lekhajnadimpalli/",
  },
  {
    name: "Silus",
    img: silus,
    link: "https://www.linkedin.com/in/silus/",
  },
  {
    name: "Rakesh",
    img: rakesh,
    link: "https://www.linkedin.com/in/rakeshvaddadi/",
  },
  {
    name: "Clinging Stars LLP",
    img: clingingStarsLLP,
    link: "#",
  },
];

export default investorsList;
