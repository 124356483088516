import nikhil from "../assets/team/nikhil.jpg";
import pruthvi from "../assets/team/pruthvi.jpg";
import surya from "../assets/team/surya.jpg";
import rishabh from "../assets/team/rishabh.jpg";
import divyansh from "../assets/team/divyansh.jpg";
import yash from "../assets/team/yash.jpg";
import maaz from "../assets/team/maaz.jpg";
import hrithik from "../assets/team/hrithik.jpg";
import dhawal from "../assets/team/dhawal.jpg";
import siva from "../assets/team/siva.jpg";
import utkarsha from "../assets/team/utkarsha.jpg";
import sidhanth from "../assets/team/sidhanth.jpg";
import sohil from "../assets/team/sohil.jpg";

const teamList = [
  {
    name: "Nikhil",
    role: "Co-founder",
    img: nikhil,
    linkedin: "https://www.linkedin.com/in/manikantanikhil/",
  },
  {
    name: "Pruthvi",
    role: "Co-founder",
    img: pruthvi,
    linkedin: "https://www.linkedin.com/in/pruthvirajeranti",
  },
  {
    name: "Surya",
    role: "Product Head",
    img: surya,
    linkedin: "https://www.linkedin.com/in/donelasurya/",
  },
  {
    name: "Rishabh",
    role: "Tech",
    img: rishabh,
    linkedin: "https://www.linkedin.com/in/rishabh-sharma-11242b174/",
  },
  {
    name: "Yash",
    role: "Tech",
    img: yash,
    linkedin: "https://www.linkedin.com/in/yash-srivastava-04802a15b/",
  },
  {
    name: "Hrithik",
    role: "Insights",
    img: hrithik,
    linkedin: "https://www.linkedin.com/in/hrithik-sammi/",
  },
  {
    name: "Divyansh",
    role: "Tech",
    img: divyansh,
    linkedin: "https://www.linkedin.com/in/divyansh-m/",
  },

  {
    name: "Maaz",
    role: "Tech",
    img: maaz,
    linkedin: "https://www.linkedin.com/in/maaz-bin-asad-8a56a2196/",
  },
  {
    name: "Dhawal",
    role: "Marketing",
    img: dhawal,
    linkedin: "https://in.linkedin.com/in/dhawalgaikwad",
  },
  {
    name: "Siva",
    role: "Design",
    img: siva,
    linkedin: "https://www.linkedin.com/in/tsivadharshan",
  },
  {
    name: "Utkarsha",
    role: "Insights",
    img: utkarsha,
    linkedin: "https://www.linkedin.com/in/utkarshapandey",
  },
  {
    name: "Sidhanth",
    role: "Insights",
    img: sidhanth,
    linkedin: "https://www.linkedin.com/in/sidhanth-paul-9427831b3",
  },
  {
    name: "Sohil",
    role: "Insights",
    img: sohil,
    linkedin: "https://www.linkedin.com/in/sohil-idnani-350a5a183",
  },
];

export default teamList;
