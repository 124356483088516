import React from "react";
import Avatar from "./Avatar";
import Spacer from "./Spacer";

const TeamCircle = (props) => {
  const nameStyle = {
    fontSize: "1.6rem",
    fontWeight: "700",
    color: "white",
  };
  const designationStyle = {
    fontSize: "1rem",
    color: "#D1D1D1",
  };
  const wrapperStyle = {
    justifyContent: "center",
    alignItems: "center",
    height: window.innerWidth < 1024 ? "20vh" : "25vh",
    width: "100%",
  };
  return (
    <a href={props.linkedin} target="_blank" rel="noreferrer">
      <div style={wrapperStyle} className="col">
        {props.img && (
          <Avatar
            img={props.img}
            diameter={window.innerWidth < 1300 ? "100px" : "140px"}
            zIndex="3"
          />
        )}
        <Spacer height="1vh" />
        <span style={nameStyle}>{props.name}</span>
        <span style={designationStyle}>{props.company}</span>
      </div>
    </a>
  );
};

export default TeamCircle;
