import React from "react";
import Carousel from "react-elastic-carousel";
import TeamCircle from "../../components/TeamCircle";
import "./Team.css";
import teamList from "../../utils/team";
import Spacer from "../../components/Spacer";

export default function Team() {
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1750, itemsToShow: 4, itemsToScroll: 2 },
  ];

  const renderTeam = () => {
    const elementsDiv = [];

    for (var i = 0; i < teamList.length; i += 2) {
      let person1 = teamList[i];
      let person2 = teamList[i + 1];
      // use this code when want to replace positions in mobile view
      // const isMobile = window.innerWidth < 600;
      // if (i === 0 && isMobile) {
      //   person2 = teamList[i + 2];
      // } else if (i === 2 && isMobile) {
      //   person1 = teamList[i - 1];
      // }
      if (i === teamList.length - 1) {
        elementsDiv.push(
          <div
            style={{ height: window.innerWidth < 1024 ? "50vh" : "70vh" }}
            className="investor-column"
          >
            <TeamCircle
              img={person1["img"]}
              name={person1["name"]}
              company={person1["role"]}
              linkedin={person1["linkedin"]}
            />
            <Spacer height="5vh" />
            <TeamCircle />
          </div>
        );
      } else {
        elementsDiv.push(
          <div
            style={{ height: window.innerWidth < 1024 ? "50vh" : "70vh" }}
            className="investor-column"
          >
            <TeamCircle
              img={person1["img"]}
              name={person1["name"]}
              company={person1["role"]}
              linkedin={person1["linkedin"]}
            />
            <Spacer height="5vh" />
            <TeamCircle
              img={person2["img"]}
              name={person2["name"]}
              company={person2["role"]}
              linkedin={person2["linkedin"]}
            />
          </div>
        );
      }
    }
    return elementsDiv;
  };

  return (
    <div id="team-sec" className="section">
      <span className="secondary-title-style">Our </span>
      <span className="primary-title-style">Tribe</span>
      <div style={{ margin: "5vh 0" }}>
        <Carousel breakPoints={breakPoints}>{renderTeam()}</Carousel>
      </div>
    </div>
  );
}
