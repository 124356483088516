import React from "react";
import Carousel from "react-elastic-carousel";
import "./Investor.css";
import investorsList from "../../utils/investors";
import Investor from "../../components/Investor";
import Spacer from "../../components/Spacer";

const Investors = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1750, itemsToShow: 4, itemsToScroll: 2 },
  ];
  const renderInvestors = () => {
    const elementsDiv = [];
    for (var i = 0; i < investorsList.length; i += 2) {
      if (i === investorsList.length - 1) {
        elementsDiv.push(
          <div className="investor-column">
            <Investor
              img={investorsList[i]["img"]}
              name={investorsList[i]["name"]}
              link={investorsList[i]["link"]}
            />
          </div>
        );
      } else {
        elementsDiv.push(
          <div className="investor-column">
            <Investor
              img={investorsList[i]["img"]}
              name={investorsList[i]["name"]}
              link={investorsList[i]["link"]}
            />
            <Spacer height="5vh" />
            <Investor
              img={investorsList[i + 1]["img"]}
              name={investorsList[i + 1]["name"]}
              link={investorsList[i + 1]["link"]}
            />
          </div>
        );
      }
    }
    return elementsDiv;
  };

  return (
    <div id="investor-sec" className="section">
      <div className="row" style={{ justifyContent: "center" }}>
        <div id="investor-box">
          <div className="row" style={{ justifyContent: "center" }}>
            <span className="secondary-title-style">Our</span>
            <Spacer width={"15px"} />
            <span className="primary-title-style">Investors</span>
          </div>

          <Carousel id="investor-carousel" breakPoints={breakPoints}>
            {renderInvestors()}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Investors;
