import "./App.css";
import React, { useEffect, useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { Navigate } from "./utils/navigator";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/home";
import PrivacyPolicyScreen from "./screens/privacy-policy";
import TNCScreen from "./screens/t-&-c";
import RefundScreen from "./screens/refund-policy";
import ContactScreen from "./screens/contact-us";
import TradingView from "./screens/tradingview";

function App() {
  const theme = createTheme({
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
      values: { xs: 0, sm: 568, md: 600, lg: 1025, xl: 1200, xxl: 1400 },
    },
  });
  const [width, setWidth] = useState(1);
  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 600 ? setWidth(10) : setWidth(1);
    };
    window.addEventListener("resize", handleResize);
  }, [width]);
  return (
    <div
      className="scroll-container"
      style={{
        background:
          "linear-gradient(110deg,#13001c 9.09%,rgba(26, 3, 58, 0.921308) 20%,rgba(25, 7, 66, 0.834476) 31%,rgba(42, 12, 132, 0.73) 45%,rgba(61, 30, 152, 0.73) 57%,rgba(42, 12, 132, 0.73) 70%)",
      }}
    >
      <MuiThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path={Navigate.HomeScreen} element={<HomeScreen />} />
            <Route
              path={Navigate.PrivacyPolicyScreen}
              element={<PrivacyPolicyScreen />}
            />
            <Route path={Navigate.TNCScreen} element={<TNCScreen />} />
            <Route path={Navigate.RefundPolicy} element={<RefundScreen />} />
            <Route path={Navigate.Contact} element={<ContactScreen />} />
            <Route path={Navigate.TradingView} element={<TradingView />} />
          </Routes>
        </Router>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
