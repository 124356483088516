import React from "react";
import "./Game.css";
import "../../index.css";
import illustration from "../../assets/game/illustration.png";
import MobileMockup from "../../components/MobileMockup";

const Game = () => {
  return (
    <div id="game-sec" className="section">
      <div className="row" id="game-container">
        <div style={{ width: "" }}>
          <div className="game-header">
            <span className="primary-title-style">Play & Learn</span>
            <br />
            <span className="secondary-title-style">Finance</span>
            <p>
              We’re bringing FUN to finance! Explore our world of games where
              you can Play & Learn
            </p>
          </div>
        </div>
        <div id="mobile-mockup-2">
          <MobileMockup
            color="#FE8600"
            rotate="9.14"
            id="mobile-img-2"
            img={illustration}
            marginTop={window.innerWidth < 1300 ? "-4rem" : "-2rem"}
          />
        </div>
      </div>
    </div>
  );
};

export default Game;
