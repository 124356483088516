import { Button } from "@material-ui/core";
import React from "react";

const CustomBtn = (props) => {
  const buttonStyle = {
    background:
      "radial-gradient(98.18% 587.58% at 4.74% 9.09%, #44E6F2 0%, #3F89DC 30.38%, #9925C8 100%)",
    color: "#fff",
    border: "none",
    width: props.width,
    padding: props.padding,
    textAlign: "center",
    textDecoration: "none",
    textTransform: "none",
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
    borderRadius: "40px",
  };
  return (
    <Button mode="outlined" onClick={props.onClick} style={buttonStyle}>
      {props.text}
    </Button>
  );
};

export default CustomBtn;
