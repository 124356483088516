import React, {Component} from "react";

class TradingView extends Component {
    render() {
        return (
            <div
                style={{
                    padding: "8vh 8vw",
                }}
            >
                <header>
                    <h1>Exciting News: iTribe Partners with <span style={{display: 'inline-block'}}><a
                        href='https://www.tradingview.com/'>TradingView</a></span> for
                        Charting</h1>
                </header>

                <section>
                    <p>We are thrilled to announce our partnership with TradingView, one of the most popular trading
                        platforms in the industry. This collaboration brings you powerful tools to enhance your trading
                        experience and stay ahead of the market.</p>
                </section>

                <section>
                    <h2>Key Benefits:</h2>
                    <ol>
                        <li>
                            <strong>Advanced Charting:</strong> TradingView provides robust charting tools, allowing you
                            to analyze market data with precision. Explore technical indicators, drawing tools, and
                            customizable chart layouts to make informed trading decisions.
                        </li>
                        <li>
                            <strong>Live Market Data:</strong> Stay up-to-date with real-time market data. Monitor the
                            price of Dow Futures and other essential assets to seize trading opportunities when they
                            arise.
                        </li>
                        <li>
                            <strong>Global News:</strong> Access the latest global news and financial updates directly
                            from TradingView. Informed traders make better decisions, and our partnership ensures you're
                            always in the know.
                        </li>
                        <li>
                            <strong>Market Insights:</strong> Benefit from valuable market insights and trends.
                            TradingView's platform empowers you to uncover trading opportunities, spot trends early, and
                            strategize effectively.
                        </li>
                    </ol>
                </section>

                <section>
                    <h2>Why TradingView?</h2>
                    <p>TradingView's user-friendly interface and feature-rich platform have made it a trusted choice for
                        traders worldwide. Whether you're a seasoned trader or just getting started, TradingView equips
                        you with the tools you need to succeed in the dynamic world of finance.</p>
                    <p>
                        Live Market Data: Stay up-to-date with real-time market data. Monitor the price of <span
                        style={{display: 'inline-block'}}><a
                        href='https://www.tradingview.com/symbols/CBOT_MINI-YM1!/'>Dow Futures</a></span> and other
                        essential assets to seize trading opportunities when they arise
                        Here is also link to </p>
                </section>

                <section>
                    <p>At iTribe, we are committed to providing you with the best resources and partnerships to elevate
                        your trading experience. Explore the power of TradingView's charting capabilities and take your
                        trading to the next level.</p>
                </section>
            </div>
        );
    }
}

export default TradingView;