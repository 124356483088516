import React, { Component } from "react";
import Header from "../containers/header/Header";
import Feature from "../containers/feature/Feature";
import Feedback from "../containers/contact/Feedback";
import Team from "../containers/team/Team";
import "./home.css";
import star from "./../assets/icons/star.svg";
import Game from "../containers/game/Game";
import Learn from "../containers/learn/Learn";
import Investors from "../containers/investor/Investor";

function generateStars() {
  const stars = [];
  for (let i = 0; i < 30; i++) {
    const size = 2 + Math.random() * 6;
    stars.push(
      <div
        style={{
          height: `${size}px`,
          width: `${size}px`,
          top: `${Math.random() * 90 + 5}%`,
          left: `calc(${Math.random() * 60 + 20}%)`,
          opacity: `${0.5 + Math.random() * 0.5}%`,
        }}
        className="animate-star"
      />
    );
  }
  for (let i = 0; i < 20; i++) {
    const size = 4 + Math.random() * 25;
    stars.push(
      <img
        src={star}
        style={{
          position: "absolute",
          height: `${size}px`,
          width: `${size}px`,
          top: `${Math.random() * 95}%`,
          left: `${Math.random() * 95}%`,
          opacity: `${0.5 + Math.random() * 0.5}%`,
        }}
        className="twinkle"
        alt="star"
      />
    );
  }
  return stars;
}

class HomeScreen extends Component {
  render() {
    return (
      <div id="website-wrapper">
        {generateStars()}
        <Header />
        <Learn />
        <Game />
        <Feature />
        <Team />
        <Investors />
        <Feedback />
      </div>
    );
  }
}

export default HomeScreen;
