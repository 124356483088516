import React from "react";

const Investor = (props) => {
  const nameStyle = {
    fontSize: "1.4rem",
    fontWeight: "500",
  };
  return (
    <div id="investor-column">
      <div className="investor-container">
        <img className="investor-img" src={props.img} alt="investor"></img>
      </div>
      <span style={nameStyle}>{props.name}</span>
    </div>
  );
};

export default Investor;
