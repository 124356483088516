import React from "react";

const Spacer = (props) => {
  const spacerStyle = {
    height: props.height,
    width: props.width,
  };
  return <div style={spacerStyle} />;
};

export default Spacer;
